import { __rest as r, __assign as o, __spreadArray as e, __read as n } from "../../../_virtual/_tslib.js";
import { jsxs as t, jsx as i } from "react/jsx-runtime";
import { useRef as a, useEffect as c } from "react";
import { Box as l, Stack as u } from "@mui/material";
import "../../../enums/entities/entities.enum.js";
import { EventKey as s } from "../../../enums/keyboard/keyboard.enum.js";
import "../../../enums/theme/theme.enum.js";
import { Icon as m } from "../../icon/icon.component.js";
import { colors as d } from "../../../themes/colors/colors.js";
import { StyledInputLabel as p, StyleFromHelperWrapper as f, StyledFormHelperErrorText as h, StyledFormHelperText as v, StyledBox as g, StyledStack as b, StyledButton as k } from "./keyboard-pin-field.styles.js";
import w from "../../../packages/pwa-ui/assets/images/icons/back-keyboard.image.svg.js";

var x = function (x) {
  var y = x.value,
      j = x.name,
      C = x.error,
      F = x.fields,
      S = void 0 === F ? 6 : F,
      A = x.onChange;
  x.onFocus, x.onBlur;
  var B = x.onComplete,
      E = x.bottomLabel,
      D = x.label,
      O = x.isShowError,
      P = void 0 === O || O;
  x.clearPlaceholderOnFocus, x.isSeparate, x.autoFocus;

  var T = x.isMobile,
      L = r(x, ["value", "name", "error", "fields", "onChange", "onFocus", "onBlur", "onComplete", "bottomLabel", "label", "isShowError", "clearPlaceholderOnFocus", "isSeparate", "autoFocus", "isMobile"]),
      M = Array.from(Array(S), function () {
    return a(null);
  }),
      $ = j || "pinfield",
      _ = y || Array.from(Array(S), function () {
    return "";
  }),
      R = P && !!C;

  c(function () {
    B && y && "" !== y[y.length - 1] && B(y);
  }, [y]);

  var V = function (r) {
    if (A) {
      var o = e([], n(_), !1),
          t = o.indexOf("");

      if (window.navigator.vibrate && window.navigator.vibrate(100), t > -1 && (o[t] = r), r === s.Backspace) {
        var i = e([], n(_), !1);
        if (0 === t) return;
        -1 === t ? i[i.length - 1] = "" : i[t - 1] = "", A(i);
      } else A(o);
    }
  };

  return t(l, o({
    mt: 1.5
  }, {
    children: [D && i(p, o({
      $color: L.color,
      htmlFor: $,
      shrink: !0
    }, {
      children: D
    })), t(l, o({
      position: "relative"
    }, {
      children: [i(u, o({
        direction: "row",
        justifyContent: "center",
        spacing: 2
      }, {
        children: M.map(function (r, o) {
          return i(l, {
            bgcolor: _[o] ? d.panPurple : d.estateViolet,
            borderRadius: "100px",
            height: "10px",
            width: "10px"
          }, "".concat($, "-").concat(o));
        })
      })), R && i(f, {
        children: i(h, o({
          id: "".concat($, "-error-text")
        }, {
          children: C
        }))
      }), E && !R ? i(f, {
        children: "string" == typeof E ? i(v, o({
          $color: L.color
        }, {
          children: E
        })) : E
      }) : null]
    })), i(g, {
      children: t(b, o({
        direction: "row",
        flexWrap: "wrap"
      }, {
        children: [Array.from({
          length: 9
        }, function (r, o) {
          return o + 1;
        }).map(function (r, e) {
          return i(k, o({
            variant: "text",
            onClick: T ? void 0 : function (o) {
              o.preventDefault(), V(r.toString());
            },
            onTouchEnd: T ? function (o) {
              o.preventDefault(), V(r.toString());
            } : void 0
          }, {
            children: r
          }), r);
        }), i(l, {
          height: "92px",
          width: "92px"
        }), i(k, o({
          variant: "text",
          onClick: T ? void 0 : function (r) {
            r.preventDefault(), V("0");
          },
          onTouchEnd: T ? function (r) {
            r.preventDefault(), V("0");
          } : void 0
        }, {
          children: "0"
        })), i(k, o({
          variant: "text",
          onClick: T ? void 0 : function () {
            return V(s.Backspace);
          },
          onTouchEnd: T ? function () {
            return V(s.Backspace);
          } : void 0
        }, {
          children: i(m, {
            height: 28,
            src: w,
            width: 40
          })
        }))]
      }))
    })]
  }));
};

export { x as KeyboardPinField };