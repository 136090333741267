import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { BROWSER_ENUM } from '@enums';
/**
 * Detects if the device is a mobile device.
 *
 * @author Yury Kuntsou
 * @function useMobileDetector
 * @category Hooks
 * @returns {boolean} Whether the device is a mobile device or not.
 */

var useMobileDetector = function useMobileDetector() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isMobile = _useState2[0],
      setMobile = _useState2[1];

  useEffect(function () {
    var hasTouchScreen = false;

    if (typeof navigator.maxTouchPoints !== 'undefined') {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else {
      var mQ = window.matchMedia && window.matchMedia('(pointer:coarse)');

      if (mQ && mQ.media === '(pointer:coarse)') {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    setMobile(function () {
      return hasTouchScreen;
    });
  }, []);
  return isMobile;
};

var getMobileOperatingSystem = function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || (globalThis === null || globalThis === void 0 ? void 0 : globalThis.opera) || '';
  return {
    android: /android/i.test(userAgent),
    ios: /iPad|iPhone|iPod/.test(userAgent) && !(globalThis !== null && globalThis !== void 0 && globalThis.MSStream)
  };
};

var getIOSVersion = function getIOSVersion() {
  var agent = window.navigator.userAgent;
  var start = agent.indexOf('OS ');

  if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1 || agent.indexOf('iPod') > -1) && start > -1) {
    return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
  }

  return 0;
};

var getBrowser = function getBrowser() {
  var testUserAgent = function testUserAgent(regexp) {
    return regexp.test(window.navigator.userAgent);
  };

  function detectBrowser() {
    switch (true) {
      case testUserAgent(/edg/i):
        return BROWSER_ENUM.EDGE;

      case testUserAgent(/trident/i):
        return BROWSER_ENUM.INTERNET_EXPLORER;

      case testUserAgent(/firefox|fxios/i):
        return BROWSER_ENUM.FIRE_FOX;

      case testUserAgent(/opr\//i):
        return BROWSER_ENUM.OPERA;

      case testUserAgent(/ucbrowser/i):
        return BROWSER_ENUM.UC_BROWSER;

      case testUserAgent(/samsungbrowser/i):
        return BROWSER_ENUM.SAMSUNG_BROWSER;

      case testUserAgent(/chrome|chromium|crios/i):
        return BROWSER_ENUM.CHROME;

      case testUserAgent(/safari/i):
        return BROWSER_ENUM.SAFARI;

      default:
        return BROWSER_ENUM.UNKNOWN;
    }
  }

  return detectBrowser();
};

export { getBrowser, getIOSVersion, getMobileOperatingSystem, useMobileDetector };