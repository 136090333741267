import { __rest as e, __assign as o } from "../../../_virtual/_tslib.js";
import { jsxs as r, jsx as i } from "react/jsx-runtime";
import { InputAdornment as t } from "@mui/material";
import { Icon as l } from "../../icon/icon.component.js";
import { IconButton as n } from "../../icon-button/icon-button.component.js";
import { StyledFormControl as a, StyledInputLabel as c, StyledInputBase as d, StyleFromHelperWrapper as s, StyledFormHelperErrorText as m, StyledFormHelperText as p } from "./text-field.styles.js";
import u from "../../../packages/pwa-ui/assets/images/clear.image.svg.js";
import { TextMask as b } from "../text-mask/text-mask.component.js";

var f = function (f) {
  var h = f.name,
      v = f.fullWidth,
      g = f.color,
      L = void 0 === g ? "white" : g,
      P = f.label,
      j = f.bottomLabel,
      k = f.error,
      x = f.maskProps;
  f.deviceSize;

  var w = f.inputRef,
      C = f.focused,
      W = f.clearable,
      y = f.clearPlaceholderOnFocus,
      F = f.placeholder,
      $ = f.inputProps,
      E = f.active,
      I = f.disableLabel,
      O = f.disableError,
      R = f.disableBottomLabel,
      z = f.bold,
      A = void 0 !== z && z,
      B = f.isLabelWrapping,
      D = void 0 !== B && B,
      S = e(f, ["name", "fullWidth", "color", "label", "bottomLabel", "error", "maskProps", "deviceSize", "inputRef", "focused", "clearable", "clearPlaceholderOnFocus", "placeholder", "inputProps", "active", "disableLabel", "disableError", "disableBottomLabel", "bold", "isLabelWrapping"]),
      _ = h && "".concat(h, "-textfield"),
      M = !!k,
      N = {
    errorId: _ && "".concat(_, "-error-text"),
    htmlFor: _,
    id: _
  },
      q = x ? {
    inputComponent: b,
    inputProps: o(o({}, $), x)
  } : {};

  return r(a, o({
    error: M,
    focused: C,
    fullWidth: v
  }, {
    children: [!I && P && i(c, o({
      $color: L,
      $wrap: D,
      htmlFor: N.htmlFor,
      shrink: !0
    }, {
      children: P
    })), i(d, o({
      $bold: A,
      "aria-describedby": _,
      autoComplete: "off",
      color: L,
      endAdornment: W && !S.readOnly && i(t, o({
        className: "InputAdornment-clearable",
        position: "end"
      }, {
        children: i(n, o({
          onClick: function () {
            (null == S ? void 0 : S.onChange) && "function" == typeof S.onChange && S.onChange(new Event("clear"), "");
          },
          onMouseDown: function (e) {
            e.preventDefault();
          }
        }, {
          children: i(l, {
            height: 12,
            src: u,
            width: 12
          })
        }))
      })),
      error: M,
      fullWidth: v,
      id: N.id,
      inputProps: $,
      inputRef: w,
      name: h,
      placeholder: y && E ? void 0 : F
    }, q, S)), !O && M && i(s, {
      children: i(m, o({
        id: N.errorId
      }, {
        children: k
      }))
    }), R || !j || M ? null : i(s, {
      children: "string" == typeof j ? i(p, o({
        $color: L
      }, {
        children: j
      })) : j
    })]
  }));
};

export { f as TextField };