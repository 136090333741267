import { CardStatus, PaymentsCardsConfirmState, PaymentsCardsNextStep } from '@enums';
import card from '@assets/images/credit-card-green.image.png';
export var getP2PCardsMock = function getP2PCardsMock() {
  return {
    defaultCards: {
      cardFrom: null,
      cardTo: null
    },
    minAmount: 1,
    otherCards: [{
      backgroundColorFrom: '',
      backgroundColorTo: '',
      cardExpiry: '11/24',
      cardId: '2882918',
      cardName: 'Копилка',
      cardNumber: '**** 4435',
      id: 0,
      isVisible: true,
      onlyRecipient: true,
      pictureURL: card
    }, {
      backgroundColorFrom: '',
      backgroundColorTo: '',
      cardExpiry: '10/25',
      cardId: 'FB2329300020406257',
      cardName: 'РРБ',
      cardNumber: '**** 9434',
      id: 1,
      isVisible: true,
      onlyRecipient: false,
      pictureURL: 'https://dev-frontend-pwa.lab.vebtech.by/remote/cards/otherCard.png'
    }, {
      backgroundColorFrom: '',
      backgroundColorTo: '',
      cardExpiry: '10/26',
      cardId: 'FB2327700000106942',
      cardName: 'Технобанк',
      cardNumber: '**** 1015',
      id: 2,
      isVisible: true,
      onlyRecipient: false,
      pictureURL: 'https://dev-frontend-pwa.lab.vebtech.by/remote/cards/otherCard.png'
    }, {
      backgroundColorFrom: '',
      backgroundColorTo: '',
      cardExpiry: '09/23',
      cardId: '3000323',
      cardName: 'Заначка',
      cardNumber: '**** 6060',
      id: 3,
      isVisible: false,
      onlyRecipient: false,
      pictureURL: card
    }],
    settings: [{
      cardFrom: '59857180',
      cardTo: ['2882918', '3000323', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '2882918',
      cardTo: ['59857180', '3000323', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: 'FB2329300020406257',
      cardTo: ['59857180', '3000323', 'FB2327700000106942']
    }, {
      cardFrom: 'FB2327700000106942',
      cardTo: ['59857180', '3000323', 'FB2329300020406257']
    }, {
      cardFrom: '3000323',
      cardTo: ['59857180', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '59856990',
      cardTo: ['59857180', '3000323', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '59871760',
      cardTo: ['59857180', '3000323', '59856990', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '59856750',
      cardTo: ['59857180', '59871760', '3000323', '59856990', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '59872100',
      cardTo: ['59871760', '3000323', '59856990', 'FB2327700000106942', 'FB2329300020406257']
    }, {
      cardFrom: '59856950',
      cardTo: ['59871760', '3000323', '59856990', 'FB2327700000106942', 'FB2329300020406257']
    }],
    userCards: [{
      RBSNumber: '3014104LOU0010226',
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 5,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****7966',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 0.06,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.05,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59868550',
      extraRsTech: '59856990',
      firstName: 'OLEG',
      iban: 'BY57BELB3014104LOU0010226000',
      ibanReadable: 'BY57 BELB 3014 104L OU00 1022 6000',
      id: 3,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01C0226',
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      backgroundColorFrom: '#021F5D',
      backgroundColorTo: '#03173A',
      cardBalance: 1.7,
      cardCurrency: 'USD',
      cardExpiry: '10/24',
      cardName: 'Visa Classic',
      cardNumber: '****7295',
      cardType: 'Visa Classic',
      equivalents: [{
        equivalentAmount: 4.83,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 1.57,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59871770',
      extraRsTech: '59871760',
      firstName: 'OLEG',
      iban: 'BY59BELB3014104LOU01C0226000',
      ibanReadable: 'BY59 BELB 3014 104L OU01 C022 6000',
      id: 4,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'VC_VIRT_DEB',
      pictureURL: card,
      productCode: 'PrvDbAAA003VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU0010226',
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 0.17,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****2717',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 0.06,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.05,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59856770',
      extraRsTech: '59856750',
      firstName: 'OLEG',
      iban: 'BY57BELB3014104LOU0010226000',
      ibanReadable: 'BY57 BELB 3014 104L OU00 1022 6000',
      id: 1,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01D0226',
      accountCurrency: 'EUR',
      accountName: 'Текущий счет EUR',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 45.95,
      cardCurrency: 'EUR',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****1535',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 139.23,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 48.25,
        equivalentCurrency: 'USD'
      }],
      extraRsRelated: '59857200',
      extraRsTech: '59857180',
      firstName: 'OLEG',
      iban: 'BY44BELB3014104LOU01D0226000',
      ibanReadable: 'BY44 BELB 3014 104L OU01 D022 6000',
      id: 5,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU01D0226',
      accountCurrency: 'EUR',
      accountName: 'Текущий счет EUR',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 45.95,
      cardCurrency: 'EUR',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****4225',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 139.23,
        equivalentCurrency: 'BYN'
      }, {
        equivalentAmount: 48.25,
        equivalentCurrency: 'USD'
      }],
      extraRsRelated: '59872110',
      extraRsTech: '59872100',
      firstName: 'OLEG',
      iban: 'BY44BELB3014104LOU01D0226000',
      ibanReadable: 'BY44 BELB 3014 104L OU01 D022 6000',
      id: 6,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }, {
      RBSNumber: '3014104LOU0040226',
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      backgroundColorFrom: '#479085',
      backgroundColorTo: '#494891',
      cardBalance: 14.9,
      cardCurrency: 'BYN',
      cardExpiry: '10/24',
      cardName: 'Белкарт-Премиум Виртуальная',
      cardNumber: '****9215',
      cardType: 'Белкарт-Премиум Виртуальная',
      equivalents: [{
        equivalentAmount: 5.17,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 4.81,
        equivalentCurrency: 'EUR'
      }],
      extraRsRelated: '59857150',
      extraRsTech: '59856950',
      firstName: 'OLEG',
      iban: 'BY12BELB3014104LOU0040226000',
      ibanReadable: 'BY12 BELB 3014 104L OU00 4022 6000',
      id: 2,
      isMulti: false,
      lastName: 'ARBIDOL',
      pictureID: 'BELKART_VIRT',
      pictureURL: card,
      productCode: 'PrvDbAAA027VIRT_TMC',
      status: {
        cardStatus: 'Активна',
        color: '',
        isDisplay: false,
        state: CardStatus.ACTIVE,
        statusCode: '00'
      }
    }]
  };
};
export var getInitP2PCardsMock = function getInitP2PCardsMock(props) {
  var values = props;
  var transactionId = new Date().getTime();

  if (values.amount && values.amount > 15000) {
    return {
      iFrameUrl: "https://egwtest.smartpro.best/pwa_form.aspx?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImV4cCI6MTcwMDk0MzgyMn0.eyJPUkRFUiI6IjIwMjMxMDI2MTMzNyIsIkFNT1VOVCI6IjIsMTIiLCJFWFRSQVJTX0ZST00iOiIiLCJFWFRSQVJTX1RPIjoiIiwiQ1VSUkVOQ1kiOiJCWU4iLCJNRVJDSEFOVCI6IkJWRUJfUFdBX1RFU1QwMl9QSyIsIlRSVFlQRSI6IjgiLCJDQVJEX1RPS0VOX0ZST00iOiIiLCJDQVJEX1RPS0VOX1RPIjoiIiwiaXNTaG93Q2hlcXVlIjoiZmFsc2UiLCJUSUQiOiI5MDMwMDAyNSIsIlRSTU9ERSI6IjEiLCJpc0NWQzIiOiJ0cnVlIiwiaXNBTU9VTlQiOiJ0cnVlIiwiaXNORVdfQ0FSRF9GUk9NIjoidHJ1ZSIsImlzTkVXX0NBUkRfVE8iOiJ0cnVlIiwiQ0hFQ0tfUkVDSVBJRU5UIjoiZmFsc2UiLCJDSEVDS19TRU5ERVIiOiJmYWxzZSIsIk1fSU5GTyI6ImV5SmljbTkzYzJWeVZYTmxja0ZuWlc1MElqb2lUVzk2YVd4c1lTODFMakFnS0ZkcGJtUnZkM01nVGxRZ01UQXVNRHNnVjJsdU5qUTdJSGcyTkNrZ1FYQndiR1ZYWldKTGFYUXZOVE0zTGpNMklDaExTRlJOVEN3Z2JHbHJaU0JIWldOcmJ5a2dRMmh5YjIxbEx6RXhOQzR3TGpBdU1DQlRZV1poY21rdk5UTTNMak0ySW4wPSIsIkJBQ0tSRUYiOiJlbXB0eV9wd2EuYXNweCJ9.MXNhtqIbZUShx32Hws1ZWSAX1CBcxVjkdblJA8-GpEkynutCVe_8s1Wwj7fE4AjAtqMIL23PI8Iaghtv8xvkI_ElebRZVaRNAySIHOYN8BZDR9-Pvjj16qLU-capfOTXodiUmNiZRZI0x3bpVqLPiUbg59B53sFA3EtTSv0E8zfVOEHC9s3FIuMMkdDiJrvu_zpOVicv2Pa_iMVHmOr20X8T_d-qZDseTFPdumyCibHLyTqJ3PTjUr6uIVysPbysGtj6F_tzahzwLhj-jJ1ypezz2z4Q4NLiIaeP1J4xH27HuLaxLb7G1MjvPM2QOvME4Mvl_pGbdUY9ZunIsCwWkQ",
      nextStep: PaymentsCardsNextStep.IFRAME,
      transactionId: transactionId.toString()
    };
  }

  var commission = values.amount && Number((Math.random() * 4).toFixed(2));
  return {
    amount: values.amount,
    commission: commission,
    nextStep: PaymentsCardsNextStep.CONFIRM,
    totalAmount: values.amount && commission ? values.amount + commission : undefined,
    transactionId: transactionId.toString()
  };
};
export var getP2PConfirmTypeConfirmMock = function getP2PConfirmTypeConfirmMock() {
  return {
    dataConfirm: {
      codeOperation: '8000',
      dataOperation: [{
        id: 1,
        opDetails: 'RhSXp3d0ttRjN'
      }],
      idOtp: 241121414,
      idTransaction: '12312313',
      messageForClient: 'На номер +37529***4580 отправлен код для подтверждения.',
      otp: true,
      pin: true
    },
    state: PaymentsCardsConfirmState.CONFIRM
  };
};
export var getP2PConfirmTypeResultMock = function getP2PConfirmTypeResultMock() {
  return {
    dataResult: {
      cardId: '5861',
      codeOperation: '8005',
      defaultCardName: 'Сохраненная *5861',
      defaultFavoriteName: '',
      idCheck: 165413,
      inFavorites: true,
      isCardSave: true,
      transactionId: '2023100601000000002',
      typeOperation: ''
    },
    state: PaymentsCardsConfirmState.RESULT
  };
};