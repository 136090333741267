import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { Box, Container, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 0, 0)
  }, theme.breakpoints.up('desktop'), {
    display: 'block',
    maxWidth: theme.typography.pxToRem(452),
    paddingTop: theme.spacing(3)
  });

  return styles;
});
var StyledSubtitle = styled(Box)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    margin: theme.spacing(2.5, 0, 3),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    textAlign: 'left'
  });

  return styles;
});
var StyledButtonsWrapper = styled(Box)(function (_ref3) {
  var theme = _ref3.theme;

  var styles = _defineProperty({
    fontSize: '0',
    padding: theme.spacing(0, 2, 4),
    textAlign: 'center'
  }, theme.breakpoints.up('desktop'), {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'left'
  });

  return styles;
});
var StyledBox = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;
  var styles = {
    // grow:       '1',
    flexGrow: 1,
    paddingTop: theme.spacing(5)
  };
  return styles;
});
var StyledLineWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;
  var styles = {
    margin: theme.spacing(3.5, 0, 1.5),
    padding: theme.spacing(0, 1)
  };
  return styles;
});
var StyledContainerWrapper = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: theme.colors.white,
    borderRadius: pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4, 2, 5)
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref7) {
  var _styles4;

  var theme = _ref7.theme;
  var styles = (_styles4 = {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }, _defineProperty(_styles4, theme.breakpoints.down('tablet'), {
    paddingLeft: 0,
    paddingRight: 0
  }), _defineProperty(_styles4, theme.breakpoints.up('desktop'), {
    display: 'block'
  }), _styles4);
  return styles;
});
export { StyledBox, StyledButtonsWrapper, StyledContainer, StyledContainerWrapper, StyledLineWrapper, StyledSubtitle, StyledWrapper };