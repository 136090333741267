import { CardStatus } from '@enums';
import card from '@assets/images/credit-card-green.image.png';
export var getAccountsApiMock = function getAccountsApiMock() {
  return {
    data: [{
      accountAmount: 4.51,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      accountNumber: '3014022600010',
      accountPrefix: '3014',
      accountSuffix: '010',
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        RBSNumber: 'string',
        accountCurrency: 'BYN',
        accountName: 'Мой зарплатный счет',
        backgroundColorFrom: '#7BA5BC',
        backgroundColorTo: '#4E7992',
        cardBalance: 3213,
        cardCurrency: 'BYN',
        cardExpiry: '2024-01-01',
        cardName: 'Зарплатная - 1',
        cardNumber: '123456789',
        cardTerm: 'string',
        cardType: 'Standard',
        equivalents: [{
          equivalentAmount: 1045,
          equivalentCurrency: 'USD'
        }, {
          equivalentAmount: 1980,
          equivalentCurrency: 'EUR'
        }],
        extraRsRelated: 'string',
        extraRsTech: '1',
        firstName: 'string',
        iban: 'BY48BELB3404102R5505A0226000',
        ibanReadable: 'string',
        id: 1,
        isMulti: false,
        lastName: 'string',
        pictureID: 'string',
        pictureURL: card,
        productCode: 'string',
        status: {
          cardStatus: 'BLOCKED',
          color: 'string',
          isDisplay: true,
          state: CardStatus.ACTIVE,
          statusCode: 'string'
        }
      }, {
        RBSNumber: 'string',
        accountCurrency: 'BYN',
        accountName: 'Мой зарплатный счет',
        backgroundColorFrom: '#3AA5BC',
        backgroundColorTo: '#DEAC12',
        cardBalance: 42132,
        cardCurrency: 'BYN',
        cardExpiry: '2025-01-01',
        cardName: 'Зарплатная - 2',
        cardNumber: '123456329',
        cardTerm: 'string',
        cardType: 'Visa Gold',
        equivalents: [{
          equivalentAmount: 14000,
          equivalentCurrency: 'BYN'
        }, {
          equivalentAmount: 13490,
          equivalentCurrency: 'EUR'
        }],
        extraRsRelated: 'string',
        extraRsTech: '2',
        firstName: 'string',
        iban: 'BY48BELB3404102R5505A0226000',
        ibanReadable: 'string',
        id: 2,
        isMulti: false,
        lastName: 'string',
        pictureID: 'string',
        pictureURL: card,
        productCode: 'string',
        status: {
          cardStatus: 'SUPERRATE',
          color: 'string',
          isDisplay: true,
          state: CardStatus.ACTIVE,
          statusCode: 'string'
        }
      }],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY14BELB30140226000102R98001',
      ibanReadable: 'BY14 BELB 3014 0226 0001 02R9 8001',
      id: 1,
      isHidden: true,
      isOverDraft: false,
      rate: 0.01
    }, {
      accountAmount: 451,
      accountCurrency: 'USD',
      accountName: 'Текущий счет USD.',
      accountNumber: '3014022600010',
      accountPrefix: '3014',
      accountSuffix: '010',
      bankCode: '226',
      bankDepartment: '99500',
      cards: [{
        RBSNumber: 'string',
        accountCurrency: 'BYN',
        accountName: 'Мой зарплатный счет',
        backgroundColorFrom: '#7BA5BC',
        backgroundColorTo: '#4E7992',
        cardBalance: 3213,
        cardCurrency: 'BYN',
        cardExpiry: '2024-01-01',
        cardName: 'Зарплатная',
        cardNumber: '123456789',
        cardTerm: 'string',
        cardType: 'Standard',
        equivalents: [{
          equivalentAmount: 1045,
          equivalentCurrency: 'USD'
        }, {
          equivalentAmount: 1980,
          equivalentCurrency: 'EUR'
        }],
        extraRsRelated: 'string',
        extraRsTech: '3',
        firstName: 'string',
        iban: 'string',
        ibanReadable: 'string',
        id: 1,
        isMulti: false,
        lastName: 'string',
        pictureID: 'string',
        pictureURL: card,
        productCode: 'string',
        status: {
          cardStatus: 'BLOCKED',
          color: 'string',
          isDisplay: true,
          state: CardStatus.ACTIVE,
          statusCode: 'string'
        }
      }, {
        RBSNumber: 'string',
        accountCurrency: 'BYN',
        accountName: 'Мой зарплатный счет',
        backgroundColorFrom: '#3AA5BC',
        backgroundColorTo: '#DEAC12',
        cardBalance: 121212,
        cardCurrency: 'USD',
        cardExpiry: '2025-01-01',
        cardName: 'Валютная',
        cardNumber: '123456329',
        cardTerm: 'string',
        cardType: 'Visa Gold2',
        equivalents: [{
          equivalentAmount: 100000,
          equivalentCurrency: 'BYN'
        }, {
          equivalentAmount: 111111,
          equivalentCurrency: 'EUR'
        }],
        extraRsRelated: 'string',
        extraRsTech: '4',
        firstName: 'string',
        iban: 'string',
        ibanReadable: 'string',
        id: 2,
        isMulti: false,
        lastName: 'string',
        pictureID: 'string',
        pictureURL: card,
        productCode: 'string',
        status: {
          cardStatus: 'SUPERRATE',
          color: 'string',
          isDisplay: true,
          state: CardStatus.ACTIVE,
          statusCode: 'string'
        }
      }],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'UER'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'BYN'
      }],
      iban: 'BY14BELB30140226000102R98002',
      ibanReadable: 'BY14 BELB 3014 0226 0001 02R9 8002',
      id: 2,
      isHidden: false,
      isOverDraft: false,
      rate: 0.01
    }, {
      accountAmount: 104.12,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN. 2',
      accountNumber: '3014022600010',
      accountPrefix: '3014',
      accountSuffix: '010',
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY14BELB30140226000102R98003',
      ibanReadable: 'BY14 BELB 3014 0226 0001 02R9 8003',
      id: 3,
      isHidden: false,
      isOverDraft: false,
      rate: 0.01
    }, {
      accountAmount: 104.12,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN. 2',
      accountNumber: '3014022600010',
      accountPrefix: '3014',
      accountSuffix: '010',
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY14BELB30140226000102R98004',
      ibanReadable: 'BY14 BELB 3014 0226 0001 02R9 8004',
      id: 4,
      isHidden: false,
      isOverDraft: false,
      rate: 0.01
    }, {
      accountAmount: 104.12,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN. 2',
      accountNumber: '3014022600010',
      accountPrefix: '3014',
      accountSuffix: '010',
      bankCode: '226',
      bankDepartment: '99500',
      cards: [],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY14BELB30140226000102R98005',
      ibanReadable: 'BY14 BELB 3014 0226 0001 02R9 8005',
      id: 5,
      isHidden: false,
      isOverDraft: false,
      rate: 0.01
    }],
    filters: [],
    pagination: []
  };
};
export var getOpenAccountCommission = function getOpenAccountCommission() {
  return {
    commissionAmount: 3,
    commissionCurrency: 'BYN'
  };
};
export var getOpenAccountResponse = function getOpenAccountResponse() {
  return {
    codeOperation: '2570',
    dataOperation: [{
      id: 1,
      opDetails: 'some details'
    }],
    idOtp: 0,
    idTransaction: '1',
    messageForClient: 'Success',
    otp: false,
    pin: true
  };
};
export var getAccountsCurrency = function getAccountsCurrency() {
  return {
    basicListCurr: [{
      dftCurr: true,
      nameCurr: 'BYN'
    }],
    listCurr: [{
      dftCurr: true,
      nameCurr: 'BYN'
    }, {
      dftCurr: false,
      nameCurr: 'USD'
    }, {
      dftCurr: false,
      nameCurr: 'EUR'
    }, {
      dftCurr: false,
      nameCurr: 'RUB'
    }]
  };
};
export var getAccountsListApiMock = function getAccountsListApiMock() {
  return {
    accounts: [{
      accountAmount: 4.51,
      accountCurrency: 'BYN',
      accountName: 'Текущий счет BYN',
      accountType: 'тип',
      cards: [card],
      defaultAccount: true,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'EUR'
      }],
      iban: 'BY14BELB30140226000102R98001',
      id: 1
    }, {
      accountAmount: 451,
      accountCurrency: 'USD',
      accountName: 'Текущий счет USD.',
      accountType: '3014022600010',
      cards: [card],
      defaultAccount: false,
      equivalents: [{
        equivalentAmount: 12.81,
        equivalentCurrency: 'UER'
      }, {
        equivalentAmount: 13.67,
        equivalentCurrency: 'BYN'
      }],
      iban: 'BY14BELB30140226000102R98002',
      id: 2
    }]
  };
};