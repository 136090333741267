import * as yup from 'yup';
import { formatNumber } from '@helpers';
import { PaymentsFreeReceiverType } from '@enums';
import { i18n } from '@localization';

var getPaymentsFreeFormSchema = function getPaymentsFreeFormSchema() {
  return yup.object().shape({
    amount: yup.number().typeError(i18n.t('forms.validation.enterAmount')).test({
      name: 'amount',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (!value) {
          return ctx.createError({
            message: i18n.t('forms.validation.enterAmount')
          });
        }

        if (fields.minAmount && value < fields.minAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.minAmount', {
              data: " ".concat(formatNumber(fields.minAmount, 2, true, ',', ' '), " ").concat(fields.currency)
            })
          });
        }

        if (fields.maxAmount && value > fields.maxAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.maxAmount', {
              data: " ".concat(formatNumber(fields.maxAmount, 2, true, ',', ' '), " ").concat(fields.currency)
            })
          });
        }

        if (fields.senderAccount && value > fields.senderAccount.accountAmount) {
          return ctx.createError({
            message: i18n.t('forms.validation.notEnoughOnAccount')
          });
        }

        return true;
      }
    }).nullable(),
    purpose: yup.string().test({
      name: 'checkPurpose',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeCode: yup.string().test({
      name: 'checkPurposeCode',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeCodeShortDescription: yup.string().test({
      name: 'checkPurposeCodeShortDescription',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    purposeLegal: yup.object().test({
      name: 'checkPurposeLegal',
      test: function test(value, ctx) {
        var fields = ctx.parent;

        if (fields.receiverType !== PaymentsFreeReceiverType.LEGAL) {
          return true;
        }

        if (!value) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        return true;
      }
    }).nullable(),
    receiverName: yup.string().test({
      name: 'checkReceiverName',
      test: function test(value, ctx) {
        if (!value || !value.length) {
          return ctx.createError({
            message: i18n.t('forms.validation.required')
          });
        }

        var fields = ctx.parent;
        var words = value.split(' ').filter(function (word) {
          return word.trim() !== '';
        });

        if (words.length < 2 && fields.receiverType !== 2) {
          return ctx.createError({
            message: i18n.t('forms.validation.fullName')
          });
        }

        return true;
      }
    }).nullable()
  });
};

export { getPaymentsFreeFormSchema };