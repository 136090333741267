import { Currency } from '@enums';
export var getInitFreepaymentMock = function getInitFreepaymentMock() {
  return {
    amount: 0.0,
    bankBIC: 'BELBBY2X',
    bankCity: 'Г.МИНСК',
    bankName: "ОАО 'БАНК БЕЛВЭБ'",
    currency: Currency.BYN,
    feeAmount: 0.0,
    maxAmount: 100000.0,
    minAmount: 1.0,
    purpose: null,
    purposeCode: '40103',
    purposeCodeShortDescription: 'Переводы между физическими лицами',
    receiverName: null,
    receiverType: 1,
    totalAmount: 0.0,
    transactionId: '1'
  };
};
export var getUpdateFreepaymentMock = function getUpdateFreepaymentMock(props) {
  var values = props;
  var feeAmount = parseFloat((values.amount * 0.01).toFixed(2));
  var totalFeeAmount = feeAmount < 1.5 ? 1.5 : feeAmount;
  return {
    amount: values.amount,
    feeAmount: totalFeeAmount,
    totalAmount: values.amount + totalFeeAmount
  };
};