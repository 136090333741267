export var TransactionResults;

(function (TransactionResults) {
  TransactionResults["FAIL"] = "FAIL";
  TransactionResults["SUCCESS"] = "SUCCESS";
})(TransactionResults || (TransactionResults = {}));

export var SuccessErrorPageTypes;

(function (SuccessErrorPageTypes) {
  SuccessErrorPageTypes["INFO"] = "info";
})(SuccessErrorPageTypes || (SuccessErrorPageTypes = {}));

export var MAX_NAME_FIELD_LENGTH = 24;
export var MAX_CODE_FIELD_LENGTH = 20;
export var AntifraudAuthStatus;

(function (AntifraudAuthStatus) {
  AntifraudAuthStatus["IS_AUTHORIZED"] = "IS_AUTHORIZED";
  AntifraudAuthStatus["IS_GUEST"] = "IS_GUEST";
})(AntifraudAuthStatus || (AntifraudAuthStatus = {}));

export var BROWSER_ENUM;

(function (BROWSER_ENUM) {
  BROWSER_ENUM["CHROME"] = "CHROME";
  BROWSER_ENUM["EDGE"] = "EDGE";
  BROWSER_ENUM["FIRE_FOX"] = "FIRE_FOX";
  BROWSER_ENUM["INTERNET_EXPLORER"] = "INTERNET_EXPLORER";
  BROWSER_ENUM["OPERA"] = "OPERA";
  BROWSER_ENUM["SAFARI"] = "SAFARI";
  BROWSER_ENUM["SAMSUNG_BROWSER"] = "SAMSUNG_BROWSER";
  BROWSER_ENUM["UC_BROWSER"] = "UC_BROWSER";
  BROWSER_ENUM["UNKNOWN"] = "UNKNOWN";
})(BROWSER_ENUM || (BROWSER_ENUM = {}));