import { pxToRem } from '@pwa/ui';
import { Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function () {
  var styles = {
    '& li': {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16)
    },
    '& li:not(:last-child)': {
      marginBottom: pxToRem(4)
    },
    '& ol': {
      listStylePosition: 'outside',
      marginBottom: 0,
      marginLeft: pxToRem(16),
      padding: 0
    },
    '& p': {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(16),
      margin: 0
    }
  };
  return styles;
});
export { StyledBox };