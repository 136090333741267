export var Currency;

(function (Currency) {
  Currency["BYN"] = "BYN";
  Currency["CNY"] = "CNY";
  Currency["EUR"] = "EUR";
  Currency["RUB"] = "RUB";
  Currency["USD"] = "USD";
})(Currency || (Currency = {}));

export var CurrencyCode;

(function (CurrencyCode) {
  CurrencyCode["BYN"] = "BYN";
  CurrencyCode["CNY"] = "\xA5";
  CurrencyCode["EUR"] = "\u20AC";
  CurrencyCode["RUB"] = "\u20BD";
  CurrencyCode["USD"] = "$";
})(CurrencyCode || (CurrencyCode = {}));

export var Card;

(function (Card) {
  Card["Visa"] = "Visa";
  Card["World"] = "World";
})(Card || (Card = {}));

export var Language;

(function (Language) {
  Language["En"] = "en";
  Language["Ru"] = "ru";
})(Language || (Language = {}));

export var AccountTransferTypes;

(function (AccountTransferTypes) {
  AccountTransferTypes["Account"] = "account";
  AccountTransferTypes["Replenish"] = "replenish";
  AccountTransferTypes["Telephone"] = "telephone";
  AccountTransferTypes["Transfer"] = "transfer";
})(AccountTransferTypes || (AccountTransferTypes = {}));

export var Breakpoint;

(function (Breakpoint) {
  Breakpoint["Desktop"] = "desktop";
  Breakpoint["Mobile"] = "mobile";
  Breakpoint["Tablet"] = "tablet";
})(Breakpoint || (Breakpoint = {}));

export var Residence;

(function (Residence) {
  Residence["BY"] = "BY";
})(Residence || (Residence = {}));

export var BannersType;

(function (BannersType) {
  BannersType[BannersType["SuperRateAllGood"] = 1] = "SuperRateAllGood";
  BannersType[BannersType["SuperRateNotEnoughMoney"] = 2] = "SuperRateNotEnoughMoney";
  BannersType[BannersType["SuperRateNoPackage"] = 3] = "SuperRateNoPackage";
  BannersType[BannersType["SuperRateNothing"] = 4] = "SuperRateNothing";
})(BannersType || (BannersType = {}));

export var PackageType;

(function (PackageType) {
  PackageType[PackageType["NoPackage"] = 0] = "NoPackage";
  PackageType[PackageType["SimplePackage"] = 1] = "SimplePackage";
  PackageType[PackageType["OptimalPackage"] = 2] = "OptimalPackage";
  PackageType[PackageType["PremiumPackage"] = 3] = "PremiumPackage";
  PackageType[PackageType["MaximumPackage"] = 4] = "MaximumPackage";
})(PackageType || (PackageType = {}));

export var ConfirmationType;

(function (ConfirmationType) {
  ConfirmationType["OTP"] = "OTP";
  ConfirmationType["PIN"] = "PIN";
})(ConfirmationType || (ConfirmationType = {}));

export var ConfirmationCode;

(function (ConfirmationCode) {
  ConfirmationCode["AccountCreate"] = "2570";
  ConfirmationCode["AccountCreateAndCardOrder"] = "2574";
  ConfirmationCode["AccountFromAccount"] = "2546";
  ConfirmationCode["AccountToAccount"] = "2544";
  ConfirmationCode["AccountToPhone"] = "5555";
  ConfirmationCode["CardActivation"] = "2112";
  ConfirmationCode["CardCVC"] = "5061";
  ConfirmationCode["CardIssue"] = "2575";
  ConfirmationCode["CardPan"] = "5060";
  ConfirmationCode["CardPermanentBlock"] = "5558";
  ConfirmationCode["CardTemporaryBlock"] = "5557";
  ConfirmationCode["CardToCard1"] = "8005";
  ConfirmationCode["CardToCard2"] = "8002";
  ConfirmationCode["CardToCard3"] = "8000";
  ConfirmationCode["ChangeCardLimits"] = "2541";
  ConfirmationCode["ChangeCardPin"] = "2542";
  ConfirmationCode["ChangePassword"] = "5022";
  ConfirmationCode["CreditRepay"] = "2020";
  ConfirmationCode["DefaultAccount"] = "5556";
  ConfirmationCode["DepositClose"] = "2422";
  ConfirmationCode["DepositOpen"] = "2410";
  ConfirmationCode["DepositReplenish"] = "2420";
  ConfirmationCode["DepositWithdraw"] = "2421";
  ConfirmationCode["Erip"] = "2300";
  ConfirmationCode["FreePayment"] = "2531";
  ConfirmationCode["InstantTransfer"] = "2588";
  ConfirmationCode["MultiCardAddChangeAccount"] = "2450";
  ConfirmationCode["OneClickPayment"] = "2538";
  ConfirmationCode["OpenAccountAndCardOrder"] = "2574";
  ConfirmationCode["PackageAddCards"] = "2579";
  ConfirmationCode["PackageCategories"] = "2578";
  ConfirmationCode["PackageChange"] = "5559";
  ConfirmationCode["PackageOpen"] = "2577";
  ConfirmationCode["PackageUpgrade"] = "5559";
  ConfirmationCode["PhoneTransfer"] = "5555";
  ConfirmationCode["TransferToClientAccount"] = "2543";
})(ConfirmationCode || (ConfirmationCode = {}));

export var PaymentEntryPoint;

(function (PaymentEntryPoint) {
  PaymentEntryPoint["FAVORITES"] = "FAVORITES";
  PaymentEntryPoint["HISTORY"] = "HISTORY";
  PaymentEntryPoint["QR"] = "QR";
  PaymentEntryPoint["TREE"] = "TREE";
  PaymentEntryPoint["TREE_PAYMENT"] = "TREE_PAYMENT";
})(PaymentEntryPoint || (PaymentEntryPoint = {}));

export var PaymentParameterDataType;

(function (PaymentParameterDataType) {
  PaymentParameterDataType[PaymentParameterDataType["STRING"] = 0] = "STRING";
  PaymentParameterDataType[PaymentParameterDataType["INTEGER"] = 1] = "INTEGER";
  PaymentParameterDataType[PaymentParameterDataType["FLOAT"] = 2] = "FLOAT";
  PaymentParameterDataType[PaymentParameterDataType["DATA"] = 3] = "DATA";
  PaymentParameterDataType[PaymentParameterDataType["BOOLEAN"] = 5] = "BOOLEAN";
})(PaymentParameterDataType || (PaymentParameterDataType = {}));

export var PaymentNextStep;

(function (PaymentNextStep) {
  PaymentNextStep["ServiceInfo"] = "ServiceInfo";
  PaymentNextStep["TransactionStart"] = "TransactionStart";
})(PaymentNextStep || (PaymentNextStep = {}));

export var PaymentTypeForm;

(function (PaymentTypeForm) {
  PaymentTypeForm["APPROVE"] = "APPROVE";
  PaymentTypeForm["INPUT"] = "INPUT";
})(PaymentTypeForm || (PaymentTypeForm = {}));

export var PaymentFromType;

(function (PaymentFromType) {
  PaymentFromType["HISTORY"] = "HISTORY";
  PaymentFromType["PAYMENT"] = "PAYMENT";
})(PaymentFromType || (PaymentFromType = {}));

export var CardActiveStatus;

(function (CardActiveStatus) {
  CardActiveStatus["STATUS_00"] = "00";
  CardActiveStatus["STATUS_1000"] = "1000";
  CardActiveStatus["STATUS_99"] = "99";
})(CardActiveStatus || (CardActiveStatus = {}));

export var CardBlockedStatus;

(function (CardBlockedStatus) {
  CardBlockedStatus["STATUS_4005"] = "4005";
})(CardBlockedStatus || (CardBlockedStatus = {}));

export var CardPermBlockedStatus;

(function (CardPermBlockedStatus) {
  CardPermBlockedStatus["STATUS_01"] = "01";
  CardPermBlockedStatus["STATUS_04"] = "04";
  CardPermBlockedStatus["STATUS_05"] = "05";
  CardPermBlockedStatus["STATUS_1004"] = "1004";
  CardPermBlockedStatus["STATUS_1005"] = "1005";
  CardPermBlockedStatus["STATUS_1041"] = "1041";
  CardPermBlockedStatus["STATUS_1043"] = "1043";
  CardPermBlockedStatus["STATUS_2005"] = "2005";
})(CardPermBlockedStatus || (CardPermBlockedStatus = {}));

export var CardStatus;

(function (CardStatus) {
  CardStatus["ACTIVATION_AVAILABLE"] = "activationAvailable";
  CardStatus["ACTIVE"] = "active";
  CardStatus["BLOCKED"] = "blocked";
  CardStatus["CLOSED"] = "closed";
  CardStatus["EXPIRES"] = "expires";
  CardStatus["FREEZED"] = "freezed";
  CardStatus["IN_PROCESSING"] = "inProcessing";
  CardStatus["IN_THE_BRANCH"] = "inTheBranch";
  CardStatus["NOT_ACTIVATED"] = "notActivated";
  CardStatus["PERM_BLOCKED"] = "permBlocked";
  CardStatus["REJECTED"] = "rejected";
  CardStatus["TEMP_BLOCKED"] = "tempBlocked";
})(CardStatus || (CardStatus = {}));

export var QueryParams;

(function (QueryParams) {
  QueryParams["CARD_FROM_ID"] = "cardFromId";
  QueryParams["CARD_TO_ID"] = "cardToId";
  QueryParams["ERIP_KEY_ID"] = "key";
  QueryParams["PAYMENT_ENTRY_POINT"] = "entryPoint";
  QueryParams["PAYMENT_IBAN"] = "paymentIban";
  QueryParams["PAYMENT_KEY_ID"] = "key";
  QueryParams["PAYMENT_TYPE_FORM"] = "paymentType";
  QueryParams["POINT_ID"] = "pointId";
  QueryParams["SEARCH_KEY"] = "search";
  QueryParams["TAB"] = "tab";
  QueryParams["USER_LOGOUT"] = "userLogout";
})(QueryParams || (QueryParams = {}));

export var AccountsIds;

(function (AccountsIds) {
  AccountsIds["ALL"] = "all";
  AccountsIds["OPEN"] = "open";
})(AccountsIds || (AccountsIds = {}));

export var NewCardId;

(function (NewCardId) {
  NewCardId["NEW"] = "NEW";
})(NewCardId || (NewCardId = {}));

export var PaymentsCardsNextStep;

(function (PaymentsCardsNextStep) {
  PaymentsCardsNextStep["CONFIRM"] = "CONFIRM";
  PaymentsCardsNextStep["IFRAME"] = "IFRAME";
})(PaymentsCardsNextStep || (PaymentsCardsNextStep = {}));

export var PaymentsCardsEntryPoint;

(function (PaymentsCardsEntryPoint) {
  PaymentsCardsEntryPoint["FAVORITES"] = "FAVORITES";
  PaymentsCardsEntryPoint["HISTORY"] = "HISTORY";
  PaymentsCardsEntryPoint["TRANSFER"] = "TRANSFER";
})(PaymentsCardsEntryPoint || (PaymentsCardsEntryPoint = {}));

export var IframeP2PMessageStatus;

(function (IframeP2PMessageStatus) {
  IframeP2PMessageStatus["CONFIRM"] = "confirm";
  IframeP2PMessageStatus["ERROR_CARD"] = "error_card";
  IframeP2PMessageStatus["ERROR_EGW"] = "error_egw";
  IframeP2PMessageStatus["ERROR_RESPONSE"] = "error_response";
  IframeP2PMessageStatus["ERROR_SERVICE"] = "error_service";
  IframeP2PMessageStatus["OK"] = "ok";
  IframeP2PMessageStatus["REDIRECT"] = "redirect";
  IframeP2PMessageStatus["SIZE"] = "size";
  IframeP2PMessageStatus["TOAOS"] = "toAOS";
})(IframeP2PMessageStatus || (IframeP2PMessageStatus = {}));

export var PaymentsCardsConfirmState;

(function (PaymentsCardsConfirmState) {
  PaymentsCardsConfirmState["CONFIRM"] = "CONFIRM";
  PaymentsCardsConfirmState["RESULT"] = "RESULT";
})(PaymentsCardsConfirmState || (PaymentsCardsConfirmState = {}));

export var PaymentsFreeEntryPoint;

(function (PaymentsFreeEntryPoint) {
  PaymentsFreeEntryPoint["FAVORITES"] = "favorites";
  PaymentsFreeEntryPoint["FREE_PAYMENT"] = "freepayment";
  PaymentsFreeEntryPoint["HISTORY"] = "history";
})(PaymentsFreeEntryPoint || (PaymentsFreeEntryPoint = {}));

export var PaymentsFreeReceiverType;

(function (PaymentsFreeReceiverType) {
  PaymentsFreeReceiverType[PaymentsFreeReceiverType["INDIVIDUAL"] = 1] = "INDIVIDUAL";
  PaymentsFreeReceiverType[PaymentsFreeReceiverType["LEGAL"] = 2] = "LEGAL";
})(PaymentsFreeReceiverType || (PaymentsFreeReceiverType = {}));

export var PaymentsFreeStatus;

(function (PaymentsFreeStatus) {
  PaymentsFreeStatus["CONFIRM"] = "CONFIRM";
  PaymentsFreeStatus["INIT"] = "INIT";
})(PaymentsFreeStatus || (PaymentsFreeStatus = {}));

export var ChangePinStatus;

(function (ChangePinStatus) {
  ChangePinStatus["CONFIRM"] = "CONFIRM";
  ChangePinStatus["CREATE"] = "CREATE";
  ChangePinStatus["INIT"] = "INIT";
})(ChangePinStatus || (ChangePinStatus = {}));

export var CardDeliveryMethod;

(function (CardDeliveryMethod) {
  CardDeliveryMethod["OFFICE"] = "0";
  CardDeliveryMethod["POST"] = "1";
})(CardDeliveryMethod || (CardDeliveryMethod = {}));

export var InstantTransferActionType;

(function (InstantTransferActionType) {
  InstantTransferActionType["ADD"] = "ADD";
  InstantTransferActionType["DELETE"] = "DELETE";
  InstantTransferActionType["MODIFY"] = "MODIFY";
})(InstantTransferActionType || (InstantTransferActionType = {}));

export var FavoritePaymentType;

(function (FavoritePaymentType) {
  FavoritePaymentType["FOLDER"] = "FOLDER";
  FavoritePaymentType["PAYMENT"] = "PAYMENT";
})(FavoritePaymentType || (FavoritePaymentType = {}));

export var FavoriteOperationType;

(function (FavoriteOperationType) {
  FavoriteOperationType["ACCOUNT_ACCOUNT_TRANSFER"] = "ACCOUNT_ACCOUNT_TRANSFER";
  FavoriteOperationType["ERIP"] = "ERIP";
  FavoriteOperationType["FREEPAYMENT"] = "FREEPAYMENT";
  FavoriteOperationType["P2P_TRANSFER"] = "P2P_TRANSFER";
  FavoriteOperationType["PHONE_NUMBER_TRANSFER"] = "PHONE_NUMBER_TRANSFER";
})(FavoriteOperationType || (FavoriteOperationType = {}));

export var Countries;

(function (Countries) {
  Countries["BELARUS"] = "BY";
  Countries["RUSSIA"] = "RU";
})(Countries || (Countries = {}));

export var InstantTransferStatus;

(function (InstantTransferStatus) {
  InstantTransferStatus["CONFIRM"] = "CONFIRM";
  InstantTransferStatus["DETAILS"] = "DETAILS";
  InstantTransferStatus["INIT"] = "INIT";
})(InstantTransferStatus || (InstantTransferStatus = {}));

export var InstantTransferEntryPoint;

(function (InstantTransferEntryPoint) {
  InstantTransferEntryPoint["FAVORITES"] = "FAVORITES";
  InstantTransferEntryPoint["HISTORY"] = "HISTORY";
})(InstantTransferEntryPoint || (InstantTransferEntryPoint = {}));

export var InstantTransferAmountType;

(function (InstantTransferAmountType) {
  InstantTransferAmountType["RECIPIENT"] = "RECIPIENT";
})(InstantTransferAmountType || (InstantTransferAmountType = {}));

export var AccountType;

(function (AccountType) {
  AccountType["BASIC"] = "basic";
  AccountType["CURRENT"] = "current";
})(AccountType || (AccountType = {}));

export var AntifraudAttributeName;

(function (AntifraudAttributeName) {
  AntifraudAttributeName["EVENT_TYPE"] = "event_type";
  AntifraudAttributeName["PAYMENT"] = "payment";
})(AntifraudAttributeName || (AntifraudAttributeName = {}));

export var AntifraudTypes;

(function (AntifraudTypes) {
  AntifraudTypes["APPROVAL_CREDIT"] = "approval_credit";
  AntifraudTypes["ERIP"] = "erip";
  AntifraudTypes["OTHER"] = "other";
  AntifraudTypes["P2P"] = "p2p";
  AntifraudTypes["RECENT_ACCOUNT"] = "recent_account";
  AntifraudTypes["TRANSFER_PHONE"] = "transfer_phone";
  AntifraudTypes["WITHDRAW_DEPOSIT"] = "withdrawalt_deposit";
})(AntifraudTypes || (AntifraudTypes = {}));

export var MORE_PAGE_TABS;

(function (MORE_PAGE_TABS) {
  MORE_PAGE_TABS["general"] = "general";
  MORE_PAGE_TABS["settings"] = "settings";
})(MORE_PAGE_TABS || (MORE_PAGE_TABS = {}));

export var PaymentsTypeOperation;

(function (PaymentsTypeOperation) {
  PaymentsTypeOperation["ERIP"] = "2300";
  PaymentsTypeOperation["FREE_PAYMENT"] = "2531";
})(PaymentsTypeOperation || (PaymentsTypeOperation = {}));