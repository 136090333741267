import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem, Typography } from '@pwa/ui';
import { Box, Container, Stack, styled } from '@mui/material';
var StyledWrapper = styled(Box)(function (_ref) {
  var theme = _ref.theme;
  var styles = {
    backgroundColor: theme.palette.white.main,
    borderRadius: pxToRem(16),
    padding: theme.spacing(3)
  };
  return styles;
});
var StyledContainer = styled(Container)(function (_ref2) {
  var theme = _ref2.theme;

  var styles = _defineProperty({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3)
  }, theme.breakpoints.up('desktop'), {
    justifyContent: 'normal'
  });

  return styles;
});
var StyledButtonsWrapper = styled(Stack)(function (_ref3) {
  var theme = _ref3.theme;
  var styles = {
    alignItems: 'center',
    padding: theme.spacing(2.5, 0, 5)
  };
  return styles;
});
var StyledTypography = styled(Typography)(function () {
  var styles = {
    wordWrap: 'break-word'
  };
  return styles;
});
export { StyledButtonsWrapper, StyledContainer, StyledTypography, StyledWrapper };