import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createSearchParams } from 'react-router-dom';
import { FavoriteOperationType, FavoritePaymentType, PaymentEntryPoint, PaymentsCardsEntryPoint, PaymentsFreeEntryPoint, QueryParams, Routes } from '@enums';
export var getPaymentFavoriteButtonLink = function getPaymentFavoriteButtonLink(favorite) {
  if (favorite.type === FavoritePaymentType.FOLDER) {
    return;
  }

  switch (favorite.paymentData.paymentType) {
    case "".concat(FavoriteOperationType.ERIP):
      return {
        state: {
          entryPoint: PaymentEntryPoint.FAVORITES,
          key: favorite.id
        },
        to: Routes.PaymentsPay
      };

    case "".concat(FavoriteOperationType.ACCOUNT_ACCOUNT_TRANSFER):
      {
        return {
          state: {
            accountCt: favorite.paymentData.paymentFor,
            accountDt: favorite.paymentData.paymentFrom
          },
          to: Routes.PaymentAccounts
        };
      }

    case "".concat(FavoriteOperationType.PHONE_NUMBER_TRANSFER):
      {
        return {
          state: {
            phoneNumber: favorite.paymentData.paymentFor
          },
          to: Routes.PaymentPhone
        };
      }

    case "".concat(FavoriteOperationType.FREEPAYMENT):
      {
        var _favorite$paymentData, _params;

        var params = (_params = {}, _defineProperty(_params, QueryParams.PAYMENT_ENTRY_POINT, PaymentsFreeEntryPoint.FAVORITES), _defineProperty(_params, "".concat(QueryParams.PAYMENT_KEY_ID), "".concat(favorite.id || '')), _defineProperty(_params, QueryParams.PAYMENT_IBAN, (_favorite$paymentData = favorite.paymentData.paymentFor) !== null && _favorite$paymentData !== void 0 ? _favorite$paymentData : ''), _params);
        var search = createSearchParams(params).toString();
        return {
          to: {
            pathname: Routes.PaymentFree,
            search: search
          }
        };
      }

    case "".concat(FavoriteOperationType.P2P_TRANSFER):
      {
        var _params3;

        var _params2 = (_params3 = {}, _defineProperty(_params3, QueryParams.PAYMENT_ENTRY_POINT, PaymentsCardsEntryPoint.FAVORITES), _defineProperty(_params3, "cardFromId", "".concat(favorite.paymentData.paymentFrom || '')), _defineProperty(_params3, "cardToId", "".concat(favorite.paymentData.paymentFor || '')), _params3);

        var _search = createSearchParams(_params2).toString();

        return {
          to: {
            pathname: Routes.PaymentCards,
            search: _search
          }
        };
      }

    default:
      return;
  }
};
export var reorder = function reorder(list, startIndex, endIndex) {
  var result = Array.from(list);

  var _result$splice = result.splice(startIndex, 1),
      _result$splice2 = _slicedToArray(_result$splice, 1),
      removed = _result$splice2[0];

  result.splice(endIndex, 0, removed);
  return result;
};