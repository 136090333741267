import miniCard from '@assets/images/mini-card.image.png';
import iconURL from '@assets/images/payment.image.png';
export var getPackageMock = function getPackageMock() {
  return {
    bannerId: 0,
    currentMoneyBack: null,
    maxGoalPercent: null,
    packageName: ''
  };
};
export var getPackagesApiMock = function getPackagesApiMock() {
  return {
    dataCurrFlagList: [{
      curr: 'BYN',
      flag: 'http://bveb.by/remote/flags/{BYN}.svg',
      id: 1
    }, {
      curr: 'USD',
      flag: 'http://bveb.by/remote/flags/{USD}.svg',
      id: 2
    }, {
      curr: 'EUR',
      flag: 'http://bveb.by/remote/flags/{EUR}.svg',
      id: 3
    }, {
      curr: 'RUB',
      flag: 'http://bveb.by/remote/flags/{RUB}.svg',
      id: 4
    }, {
      curr: 'CNY',
      flag: 'http://bveb.by/remote/flags/{CNY}.svg',
      id: 5
    }],
    dataPackageList: [{
      additionalServices: [{
        code: '00001',
        fullDescription: 'Основная Основная Основная Основная Основная',
        fullName: 'карточка',
        isUp: true,
        shortDescription: 'Infinite\nДоп. карточки - Platinum, 2 Gold, Classic',
        shortName: 'Основная карточка(бесплатно)'
      }, {
        code: '00002',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Мультисчетовой функционал'
      }, {
        code: '00003',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Все категории-1%</b>Продукты - 0,5%',
        shortName: 'Money-back'
      }, {
        code: '00004',
        fullDescription: 'х',
        fullName: 'x',
        isUp: true,
        shortDescription: 'Без ограничений в любом банкомате РБ (по карточке Infinite)',
        shortName: 'Снятие наличных'
      }, {
        code: '00005',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'х'
      }, {
        code: '00006',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Персональный менеджер'
      }, {
        code: '00007',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Возмещение расходов за посещение бизнес-залов аэропортов'
      }, {
        code: '00008',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Суперкурс (обмен валют)'
      }],
      bankCode: '226',
      freeServiceTerms: [{
        code: 'BNAL_D',
        description: 'Расчеты карточкой в магазинах и в Интернете на сумму не менее чем',
        shortName: 'Объём безналичных расходных операций',
        thresholdAmount: 300000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'BNAL_C',
        description: 'Зачисление заработной платы не менее',
        shortName: 'Перечисление на счёт з/п',
        thresholdAmount: 300000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'MIDDAY',
        description: 'Совокупный среднедневной остаток по текущим счетам в белорусских рублях в Банке не менее',
        shortName: 'Среднедневной остаток по счетам в BYN',
        thresholdAmount: 300000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'MIDVKL',
        description: 'Совокупный среднедневной остаток по вкладам (депозитам) в белорусских рублях в Банке не менее',
        shortName: 'Среднедневной остаток по вкладам в BYN',
        thresholdAmount: 9000000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'MDUSEU',
        description: 'Совокупный среднедневной остаток по вкладам (депозитам) в долларах США и евро в Банке не менее',
        shortName: 'Среднедневной остаток по вкладам в USD и EUR в эквиваленте BYN',
        thresholdAmount: 15000000,
        thresholdAmountCurr: 'BYN'
      }],
      id: 1,
      packageCategory: '50',
      packageId: '2205312260000001',
      packageName: 'Всё по максимуму',
      packageType: '04',
      paymentFrequency: '/в мес.',
      paymentNextMonth: 50.0,
      paymentNextMonthCur: 'BYN',
      possibilityFreeService: true,
      urlForIcon: miniCard
    }, {
      additionalServices: [{
        code: '00001',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Gold Доп. карточки - Classic',
        shortName: 'Основная карточка(бесплатно)'
      }, {
        code: '00002',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Мультисчетовой функционал'
      }, {
        code: '00003',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Все категории-0,7% Продукты - 0,5%',
        shortName: 'Money-back'
      }, {
        code: '00004',
        fullDescription: 'х',
        fullName: 'В сеть СБС входят ОАО Банк БелВЭБ, ОАО Технобанк, ОАО Банк Дабрабыт, ЗАО РРБ-Банк. Снятие в банкоматах указанных банков, а также банкоматах ЗАО Альфа-Банк, РУПБелпочта бесплатно.',
        isUp: true,
        shortDescription: 'Бесплатно в сети СБС 3 раза/мес в любом банкомате РБ (по карточке Gold)',
        shortName: 'Снятие наличных'
      }, {
        code: '00005',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'х'
      }, {
        code: '00006',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Персональный менеджер'
      }, {
        code: '00007',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Возмещение расходовза посещение бизнес-заловаэропортов'
      }, {
        code: '00008',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Суперкурс (обмен валют)'
      }],
      bankCode: '226',
      freeServiceTerms: [{
        code: '',
        description: '',
        shortName: '',
        thresholdAmount: 0,
        thresholdAmountCurr: ''
      }],
      id: 2,
      packageCategory: '50',
      packageId: '2003022260000002',
      packageName: 'Всё премиально',
      packageType: '03',
      paymentFrequency: '/в мес.',
      paymentNextMonth: 15.0,
      paymentNextMonthCur: 'BYN',
      possibilityFreeService: true,
      urlForIcon: miniCard
    }, {
      additionalServices: [{
        code: '00001',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Gold Доп. карточки - Classic',
        shortName: 'Основная карточка(бесплатно)'
      }, {
        code: '00002',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Мультисчетовой функционал'
      }, {
        code: '00003',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Все категории-0,7% Продукты - 0,5%',
        shortName: 'Money-back'
      }, {
        code: '00004',
        fullDescription: 'х',
        fullName: 'В сеть СБС входят ОАО Банк БелВЭБ, ОАО Технобанк, ОАО Банк Дабрабыт, ЗАО РРБ-Банк. Снятие в банкоматах указанных банков, а также банкоматах ЗАО Альфа-Банк, РУПБелпочта бесплатно.',
        isUp: true,
        shortDescription: 'Бесплатно в сети СБС 3 раза/мес в любом банкомате РБ (по карточке Gold)',
        shortName: 'Снятие наличных'
      }, {
        code: '00005',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'х'
      }, {
        code: '00006',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Персональный менеджер'
      }, {
        code: '00007',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Возмещение расходов за посещение бизнес-залов аэропортов'
      }, {
        code: '00008',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Суперкурс (обмен валют)'
      }, {
        code: '00009',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Бесплатно при выполнении условий или 5,00 BYN',
        shortName: 'Обслуживание в месяц'
      }, {
        code: '00010',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: '1,75 BYN',
        shortName: 'SMS-оповещение(опционально)'
      }],
      bankCode: '226',
      freeServiceTerms: [{
        code: 'BNAL_C',
        description: 'Зачисление заработной платы не менее',
        shortName: 'Перечисление на счёт з/п',
        thresholdAmount: 75000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'BNAL_D',
        description: 'Расчеты карточкой в магазинах и в Интернете на сумму не менее чем',
        shortName: 'Объём безналичных расходных операций',
        thresholdAmount: 50000,
        thresholdAmountCurr: 'BYN'
      }, {
        code: 'MIDDAY',
        description: 'Совокупный среднедневной остаток по текущим счетам в белорусских рублях в Банке не менее',
        shortName: 'Среднедневной остаток по счетам в BYN',
        thresholdAmount: 100000,
        thresholdAmountCurr: 'BYN'
      }],
      id: 3,
      packageCategory: '50',
      packageId: '2003022260000002',
      packageName: 'Всё оптимально',
      packageType: '02',
      paymentFrequency: '/в мес.',
      paymentNextMonth: 5.0,
      paymentNextMonthCur: 'BYN',
      possibilityFreeService: true,
      urlForIcon: miniCard
    }, {
      additionalServices: [{
        code: '00001',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Classic',
        shortName: 'Основная карточка(бесплатно)'
      }, {
        code: '00002',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Мультисчетовой функционал'
      }, {
        code: '00003',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Money-back'
      }, {
        code: '00004',
        fullDescription: 'х',
        fullName: 'В сеть СБС входят ОАО Банк БелВЭБ, ОАО Технобанк, ОАО Банк Дабрабыт, ЗАО РРБ-Банк. Снятие в банкоматах указанных банков, а также банкоматах ЗАО Альфа-Банк, РУПБелпочта бесплатно.',
        isUp: true,
        shortDescription: 'Бесплатно в сети СБС',
        shortName: 'Снятие наличных'
      }, {
        code: '00005',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'х'
      }, {
        code: '00006',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'х',
        shortName: 'Персональный менеджер'
      }, {
        code: '00007',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Y',
        shortName: 'Возмещение расходов за посещение бизнес-залов аэропортов'
      }, {
        code: '00008',
        fullDescription: 'х',
        fullName: 'х',
        isUp: true,
        shortDescription: 'Y',
        shortName: 'Суперкурс (обмен валют)'
      }],
      bankCode: '226',
      freeServiceTerms: null,
      id: 4,
      packageCategory: '50',
      packageId: '2003022260000001',
      packageName: 'Всё просто',
      packageType: '01',
      paymentFrequency: '/в мес.',
      paymentNextMonth: 0.0,
      paymentNextMonthCur: 'BYN',
      possibilityFreeService: true,
      urlForIcon: miniCard
    }],
    hasPackage: false
  };
};
export var getPackageMoneybackMock = function getPackageMoneybackMock() {
  return {
    backgroundColor: '#2A2930',
    moneyBack: {
      amount: 20,
      currency: 'BYN',
      month: 'Апрель'
    },
    packageCategory: 'kkkk',
    packageName: 'Все по максимуму'
  };
};
export var getPackageConditionsMock = function getPackageConditionsMock() {
  return {
    serviceCharge: {
      amount: 25,
      currency: 'BYN',
      month: 'Апрель'
    },
    waivers: [{
      name: 'Объем безналичных расходных операций',
      percent: 5,
      valueFact: 150,
      valuePlan: 1000
    }, {
      name: 'Перечисление на счет зп',
      percent: 5,
      valueFact: 8000,
      valuePlan: 1000
    }, {
      name: 'Среднедневной остаток по счетам в BYN',
      percent: 5,
      valueFact: 50,
      valuePlan: 1000
    }, {
      name: 'Среднедневной остаток по вкладам в BYN',
      percent: 5,
      valueFact: 0,
      valuePlan: 1000
    }, {
      name: 'Среднедневной остаток по вкладам в USD и EUR в эквиваленте',
      percent: 5,
      valueFact: 500,
      valuePlan: 1000
    }]
  };
};
export var getPackageAdditionalServicesMock = function getPackageAdditionalServicesMock() {
  return [{
    code: '00006',
    fullDescription: 'Lorem ipsom Lorem ipsomLorem ipsom Lorem ipsomLorem ipsom Lorem ipsom Lorem ipsom Lorem ipsom',
    iconURL: '/remote/additionalServices/00006.svg',
    shortDescription: 'Lorem ipsom',
    shortName: 'Персональный менеджер'
  }, {
    code: '00002',
    fullDescription: 'Lorem ipsom Lorem ipsomLorem ipsom Lorem ipsomLorem ipsom Lorem ipsom Lorem ipsom Lorem ipsom',
    iconURL: '/remote/additionalServices/00002.svg',
    shortDescription: 'Lorem ipsom',
    shortName: 'Мультисчетовой функционал'
  }, {
    code: '00004',
    fullDescription: 'Lorem ipsom Lorem ipsomLorem ipsom Lorem ipsomLorem ipsom Lorem ipsom Lorem ipsom Lorem ipsom',
    iconURL: '/remote/additionalServices/00002.svg',
    shortDescription: 'Без ограничений в любом банкомате РБ',
    shortName: 'Снятие наличных'
  }];
};
export var getPackageCategoriesMock = function getPackageCategoriesMock() {
  return {
    categories: [{
      code: 'Питомцы',
      iconURL: iconURL,
      isConnected: true,
      name: 'Питомцы',
      rate: 2
    }, {
      code: 'Красота',
      iconURL: iconURL,
      isConnected: true,
      name: 'Красота',
      rate: 2
    }, {
      code: 'Развлечения',
      iconURL: iconURL,
      isConnected: true,
      name: 'Развлечения',
      rate: 2
    }, {
      code: 'Продукты',
      iconURL: iconURL,
      isConnected: false,
      name: 'Продукты',
      rate: 2
    }, {
      code: 'Авто',
      iconURL: iconURL,
      isConnected: false,
      name: 'Авто',
      rate: 2
    }],
    maxNumber: 3,
    nextDateChange: '2023-10-20'
  };
};
export var getPackageConnectedCardsMock = function getPackageConnectedCardsMock() {
  return {
    cards: [{
      cardCurrency: 'BYN',
      cardExpiry: '11/24',
      cardName: 'Моя зарплатная карта',
      cardNumber: '****4435',
      extraRsTech: '2882918',
      pictureURL: iconURL
    }, {
      cardCurrency: 'BYN',
      cardExpiry: '11/24',
      cardName: 'Моя расчетная карта',
      cardNumber: '****4436',
      extraRsTech: '2882919',
      pictureURL: '/remote/cards/VISA_GOLD.png'
    }],
    cardsForActivation: [{
      cardCurrency: 'BYN',
      cardExpiry: '11/24',
      cardNumber: '****4436',
      cardStatus: 'Ожидает активацию',
      cardType: 'Visa Classic',
      colorStatus: '#46BE87',
      extraRsTech: '2882919',
      isReady: true,
      pictureURL: '/remote/cards/VISA_VISA_PLATINUM.png'
    }, {
      cardCurrency: 'BYN',
      cardExpiry: null,
      cardNumber: null,
      cardStatus: 'Заявка в обработке',
      cardType: 'БЕЛКАРТ-ПРЕМИУМ МИР',
      colorStatus: '#F4B455',
      extraRsTech: null,
      isReady: false,
      pictureURL: '/remote/cards/BELCARD-PREMIUM.png'
    }],
    maxNumber: 3
  };
};
export var getPackageAvailableCardsMock = function getPackageAvailableCardsMock() {
  return {
    cards: [{
      cardBalance: 0,
      cardCurrency: 'BYN',
      cardExpiry: '11/24',
      cardName: 'Моя зарплатная карта',
      cardNumber: '****4435',
      equivalents: [{
        equivalentAmount: 0.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.0,
        equivalentCurrency: 'EUR'
      }],
      extraRsTech: '288291',
      pictureURL: 'pictureURL',
      productCode: 'PrvDbAAA027OUTPSM_TMC',
      productCodeNew: 'PrvDbAAA027PSPROSTO_TMC'
    }, {
      cardBalance: 0,
      cardCurrency: 'BYN',
      cardExpiry: '11/24',
      cardName: 'Моя зарплатная карта',
      cardNumber: '****4435',
      equivalents: [{
        equivalentAmount: 0.0,
        equivalentCurrency: 'USD'
      }, {
        equivalentAmount: 0.0,
        equivalentCurrency: 'EUR'
      }],
      extraRsTech: '2882918',
      pictureURL: 'pictureURL',
      productCode: 'PrvDbAAA027OUTPSM_TM',
      productCodeNew: 'PrvDbAAA027PSPROSTO_TM'
    }],
    isCardIssueBanned: false
  };
};
export var getPackageOpenMock = function getPackageOpenMock() {
  return {
    accountsIdCheck: [{
      idCheck: 654,
      isSuccess: true,
      message: 'Счет в BYN'
    }, {
      idCheck: null,
      isSuccess: true,
      message: 'Счет в USD'
    }, {
      idCheck: 656,
      isSuccess: true,
      message: 'Установка счета по умолчанию'
    }],
    packageIdCheck: {
      idCheck: 652,
      isSuccess: true,
      message: 'Все по максимуму'
    },
    packageName: 'Все по максимуму'
  };
};